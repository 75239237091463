import css from 'styled-jsx/css'

export const NewsSectionStyle = css.global`
  .news-section {
    padding: 40px 0 120px 0;
    background-color: #fff;
  }
  .news-section .heading {
    font-size: 36px;
    line-height: 46px;
    color: #262626;
    margin-bottom: 56px;
    text-align: center;
  }
  .news-section .card-wrapper {
    border-radius: 10px;
    box-shadow: 1px 3px 16px 0 rgba(0, 0, 0, 0.1);
    background: #ffffff;
    height: 100%;
  }
  .news-section .slick-slider {
    width: 100%;
  }

  .news-section .slick-slide {
    padding: 10px 16px;
  }
  .news-section .slick-next {
    right: 50%;
    top: 100%;
  }
  .news-section .slick-prev {
    top: 100%;
    right: 55%;
    left: unset;
  }
  .news-section .content {
    padding: 24px;
  }
  .news-section .content .title {
    font-size: 24px;
    line-height: 1.33;
    color: #262626;
    font-weight: 500;
    margin-bottom: 16px;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .news-section .content .detail {
    font-size: 16px;
    color: #595959;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 24px;
  }
  .news-section .content .btn-view {
    color: #1890ff;
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    .news-section .heading {
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 32px;
    }
    .news-section .content {
      padding: 16px;
    }
    .news-section .content .title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 8px;
    }
    .news-section .content .detail {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 16px;
    }
    .news-section .content .btn-view {
      font-size: 14px;
    }
    .news-section .slick-next {
      right: 45%;
    }
    .news-section .slick-prev {
      right: 65%;
    }
  }
`
