import React, { FC, useState } from 'react'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Collapse } from 'src/@legacy/@core/components/share/ANTD/Collapse'
import { WrapperLayout } from 'src/@legacy/@core/components/share/Wrapper/WrapperLayout'
import { Row, Col } from 'src/@legacy/@core/components/share/ANTD/Grid'
import { FAQSectionStyle } from 'src/@legacy/style/styled/LP/FAQSectionStyle.style'
import { Trans } from 'react-i18next'
import { LINK_URL_CHAT_WITH_SUPPORT, LINK_URL_SUPPORT } from 'src/@legacy/utilities/LinkURL'
import Image from 'next/image'
const { Panel } = Collapse

const FAQSection: FC = () => {
  const { translate } = useTranslation()
  const [activeItems, setActiveItems] = useState(['1'])

  const callback = (key) => {
    setActiveItems(key)
  }

  const genExtra = (index) => {
    const active = activeItems.includes(index.toString())
    return active ? <MinusOutlined className="color-main" /> : <PlusOutlined className="color-main" />
  }
  return (
    <>
      <style jsx global>
        {FAQSectionStyle}
      </style>
      <div className="qa-section">
        <WrapperLayout className="wrapper-container">
          <Row>
            <Col xs={24} lg={24}>
              <h2 className="heading">{translate('page_employer_lp:qa_section_title')}</h2>
              <div className="qa-section-collapse">
                <Collapse
                  ghost
                  bordered
                  defaultActiveKey={activeItems}
                  activeKey={activeItems}
                  onChange={callback}
                  expandIconPosition={'left'}>
                  <Panel
                    showArrow={false}
                    header={<h3 className="ant-collapse-header">{translate('page_employer_lp:qa_question_one')}</h3>}
                    key="1"
                    extra={genExtra(1)}>
                    <div className="text" dangerouslySetInnerHTML={{ __html: translate('page_employer_lp:qa_answer_one') }} />
                  </Panel>
                  <Panel
                    showArrow={false}
                    header={<h3 className="ant-collapse-header">{translate('page_employer_lp:qa_question_two')}</h3>}
                    key="2"
                    extra={genExtra(2)}>
                    <div className="text" dangerouslySetInnerHTML={{ __html: translate('page_employer_lp:qa_answer_two') }} />
                  </Panel>
                  <Panel
                    showArrow={false}
                    header={<h3 className="ant-collapse-header">{translate('page_employer_lp:qa_question_three')}</h3>}
                    key="3"
                    extra={genExtra(3)}>
                    <div className="text">
                      <Trans i18nKey="page_employer_lp:qa_answer_three">
                        <a href={LINK_URL_CHAT_WITH_SUPPORT} target="blank" />
                      </Trans>
                    </div>
                  </Panel>
                  <Panel
                    showArrow={false}
                    header={<h3 className="ant-collapse-header">{translate('page_employer_lp:qa_question_four')}</h3>}
                    key="4"
                    extra={genExtra(4)}>
                    <div className="text" dangerouslySetInnerHTML={{ __html: translate('page_employer_lp:qa_answer_four') }} />
                  </Panel>
                  <Panel
                    showArrow={false}
                    header={<h3 className="ant-collapse-header">{translate('page_employer_lp:qa_question_five')}</h3>}
                    key="5"
                    extra={genExtra(5)}>
                    <div className="text">
                      <Trans i18nKey="page_employer_lp:qa_answer_five">
                        <a href={LINK_URL_SUPPORT} target="blank" />
                      </Trans>
                    </div>
                  </Panel>
                  <Panel
                    showArrow={false}
                    header={<h3 className="ant-collapse-header">{translate('page_employer_lp:qa_question_six')}</h3>}
                    key="6"
                    extra={genExtra(6)}>
                    <div className="text" dangerouslySetInnerHTML={{ __html: translate('page_employer_lp:qa_answer_six') }} />
                  </Panel>
                  <Panel
                    showArrow={false}
                    header={<h3 className="ant-collapse-header">{translate('page_employer_lp:qa_question_seven')}</h3>}
                    key="7"
                    extra={genExtra(7)}>
                    <div className="text" dangerouslySetInnerHTML={{ __html: translate('page_employer_lp:qa_answer_seven') }} />
                  </Panel>
                </Collapse>
              </div>
            </Col>
          </Row>
        </WrapperLayout>
        <div className="faq-background">
          <Image src="/img/LP/faq_bg.png" alt="bg" width={300} height={700} />
        </div>
      </div>
    </>
  )
}

export default FAQSection
