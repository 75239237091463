import React, { FC } from 'react'
import Slider from 'react-slick'
import { SlickSliderStyle } from 'src/@legacy/@core/style/styled/Slick.style'

const SlickSlider: FC<any> = (props) => (
  <>
    <style jsx global>
      {SlickSliderStyle}
    </style>
    <Slider {...props} />
  </>
)
export default SlickSlider
