import React, { FC } from 'react'
import { LayoutStyle } from 'src/@legacy/@core/style/layout/layout.style'

export const WrapperLayout: FC<{ className: any }> = ({ className, children }) => {
  return (
    <>
      <style jsx global>
        {LayoutStyle}
      </style>
      <div className={className}>{children}</div>
    </>
  )
}
