import css from 'styled-jsx/css'

export const WhySectionStyle = css.global`
  .why-section {
    padding: 80px 0 40px 0;
    background-color: #fff;
  }
  .why-section .heading {
    font-size: 36px;
    color: #262626;
    margin-bottom: 56px;
    text-align: center;
  }
  .why-section .block-wrapper {
    padding: 30px;
  }
  .why-section .block-wrapper .image-wrapper {
    margin-bottom: 25px;
  }
  .why-section .block-wrapper .description {
    font-size: 20px;
    line-height: 30px;
    color: #8c8c8c;
  }
  .why-section .block-wrapper .high-light {
    color: #262626;
    font-weight: 500;
  }
  @media screen and (max-width: 768px) {
    .why-section {
      padding: 30px 0;
    }
    .why-section .heading {
      font-size: 28px;
      margin-bottom: 32px;
      line-height: 35px;
    }
    .why-section .block-wrapper {
      padding: 0;
    }
  }
  @media screen and (max-width: 576px) {
    .why-section .block-wrapper {
      display: flex;
      align-items: center;
    }
    .why-section .block-wrapper .image-wrapper {
      margin-bottom: 0;
      width: 48px;
      height: 48px;
      margin-right: 20px;
    }
    .why-section .block-wrapper .description {
      font-size: 16px;
      line-height: 24px;
    }
  }
`
