import css from 'styled-jsx/css'

export const FirstSectionStyle = css.global`
  .first-section {
    padding: 174px 0 65px 0;
    background-color: #fff;
  }
  .first-section .wrapper-container {
    position: relative;
  }
  .first-section .scroll-mouse {
    position: fixed;
    bottom: 20%;
    right: 0;
    z-index: 100;
  }
  .first-section .scroll-hide {
    display: none;
  }
  .first-section .scroll-mouse .scroll-text {
    transform: rotate(90deg);
    color: #bfbfbf;
    display: flex;
    align-items: center;
  }
  .first-section .scroll-mouse .scroll-divider {
    width: 17px;
    border-bottom: 1px solid #bfbfbf;
    margin: 12px;
  }
  .first-section .scroll-mouse .icon-mouse {
    transform: rotate(-90deg);
  }
  .first-section .block-left .heading {
    font-size: 40px;
    line-height: 1.35;
    color: #262626;
    margin-bottom: 11px;
  }
  .first-section .block-left .sub-title {
    font-size: 18px;
    line-height: 1.5;
    color: #8c8c8c;
    margin-bottom: 40px;
    white-space: break-spaces;
  }
  .first-section .block-left .sub-title .high-light {
    color: #262626;
    font-weight: 500;
  }
  body .first-section .block-left .btn-post-job {
    font-size: 18px;
    height: 56px;
    box-shadow: 2px 4px 16px 0 rgba(24, 144, 255, 0.3);
    padding-left: 36px;
    padding-right: 36px;
  }
  .first-section .block-right {
    background: url('/img/LP/first_bg.png') no-repeat;
    background-position: left bottom;
    background-size: contain;
    margin-top: 60px;
  }
  .first-section .block-right .impress-card {
    border-radius: 13px;
    border: solid 1px #e8e8e8;
    padding: 21px;
    text-align: center;
    background-color: #fff;
  }
  .first-section .block-right .number {
    margin: 25px 0 4px 0;
    font-size: 32px;
    font-weight: 500;
    color: #262626;
  }
  .first-section .block-right .detail {
    color: #595959;
    font-weight: 500;
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    .first-section {
      padding: 32px 0;
    }
    .first-section .scroll-mouse {
      display: none;
    }
    .first-section .block-left .heading {
      font-size: 28px;
      line-height: 1.25;
      margin-bottom: 8px;
      text-align: center;
    }
    .first-section .block-left .sub-title {
      font-size: 14px;
      text-align: center;
      margin-bottom: 16px;
    }
    .first-section .block-left .button-wrapper {
      display: flex;
      justify-content: center;
    }
    .first-section .block-left .btn-post-job {
      height: 48px;
      font-size: 16px;
      text-align: center;
    }
    .first-section .block-right {
      background: none;
      margin-top: 36px;
    }
    .first-section .block-right .impress-card {
      display: flex;
      padding: 16px 24px;
    }
    .first-section .block-right .wrapper-image {
      margin-right: 24px;
    }
    .first-section .block-right .number {
      margin: 0;
      font-size: 24px;
      display: flex;
    }
    .first-section .block-right .detail {
      font-size: 14px;
    }
  }
`
