import React, { FC } from 'react'
import { PartnerSectionStyle } from 'src/@legacy/style/styled/LP/PartnerSectionStyle.style'
import { WrapperLayout } from 'src/@legacy/@core/components/share/Wrapper/WrapperLayout'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import Image from 'next/image'
import { LIST_LOGO_PARTNER } from 'src/@legacy/utilities/Enum'
import { LINK_URL_COMPANY_PUBLISH_JOBS_SLUG, LINK_URL_JOB_PUBLIC } from 'src/@legacy/utilities/LinkURL'

const PartnerSection: FC = () => {
  const { translate } = useTranslation()
  return (
    <>
      <style jsx>{PartnerSectionStyle}</style>
      <div className="partner-section">
        <WrapperLayout className="wrapper-container">
          <h2 className="heading">{translate('page_employer_lp:partner_section_heading')}</h2>
          <div className="partner-wrapper">
            {LIST_LOGO_PARTNER.map((item) => (
              <a
                href={item?.slug ? LINK_URL_COMPANY_PUBLISH_JOBS_SLUG(item.slug, item.company_id) : LINK_URL_JOB_PUBLIC}
                target="blank"
                className="image-wrapper"
                key={item.id}>
                <Image src={item.url} alt={`logo-${item.id}`} width={155} height={120} />
              </a>
            ))}
          </div>
        </WrapperLayout>
      </div>
    </>
  )
}

export default PartnerSection
