import { useEffect, useState } from 'react'

declare global {
  interface Window {
    grecaptcha: any
  }
}

const handleVerifyReCaptcha = (token) => {
  const VERIFY_URL = `/api/verify-recaptcha?token=${token}`
  return fetch(VERIFY_URL, { method: 'GET' })
    .then((response) => response.json())
    .then((json) => {
      return json
    })
    .catch(() => {
      return { err: true }
    })
}

export const verifyRecaptcha = (values, callback) => {
  return window.grecaptcha.ready(() => {
    return window.grecaptcha.execute(process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_API_KEY, { action: 'submit' }).then((token) => {
      return handleVerifyReCaptcha(token)
        .then((res) => {
          const { score } = res
          if (score > 0.2) {
            return callback(true)
          }
          return callback(false)
        })
        .catch(() => callback(false))
    })
  })
}

export const useRecaptcha = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_API_KEY}`,
      function () {
        setLoaded(true)
      }
    )
  }, [])

  return {
    loaded
  }
}
