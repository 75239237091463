import { useCurrentUser } from 'src/@legacy/@core/hooks/CurrentUserHooks'
import { useAppRouter } from 'src/@legacy/@core/hooks/UseAppRouter'
import { X_AUTH_TOKEN } from '../@core/utilities/Client'
import { readToken } from '../@core/utilities/JWTToken'
import React from 'react'
import { useEffect } from 'react'
import { getCookie } from './Cookies'
const indexPage = `/[companyID]/jobs`
const createPageUtils = ({ loginPage, getPageIndex }) => {
  return {
    anonymousAccess: (Page) => {
      return (props) => {
        const [user] = useCurrentUser()
        const router = useAppRouter()
        useEffect(() => {
          const jwtString = getCookie(X_AUTH_TOKEN)
          if (user?.profile && jwtString) {
            const jwt = readToken(jwtString)
            router.push(getPageIndex({ jwt }))
          }
        }, [user?.profile])
        return <Page {...props} />
      }
    },
    privateAcess: (Page) => {
      return (props) => {
        const router = useAppRouter()
        useEffect(() => {
          if (!getCookie(X_AUTH_TOKEN)) {
            router.push(loginPage)
          }
        }, [])
        return <Page {...props} />
      }
    }
  }
}
export const { anonymousAccess, privateAcess } = createPageUtils({
  loginPage: '/login',
  getPageIndex: ({ jwt }) => indexPage.replace(/\[companyID\]/g, jwt.company_id?.toString())
})
