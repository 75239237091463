import { FC, useCallback, useRef, useState } from 'react'
import { useStoreTokenAndFetchUser } from 'src/@legacy/@core/hooks/CurrentUserHooks'
import { useAppRouter } from 'src/@legacy/@core/hooks/UseAppRouter'
import { useDimention } from 'src/@legacy/@core/hooks/UseDimention'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { readToken } from 'src/@legacy/@core/utilities/JWTToken'
import RegisterSocialModal from 'src/@legacy/components/RegisterSocialModal'
import { submitRegisterSocialAPI } from 'src/@legacy/service/api/register.api'
import { toStringFromQuery } from 'src/@legacy/utilities/common'
import { LINK_URL_COMPANY_JOB_LIST } from 'src/@legacy/utilities/LinkURL'

type OnregisterError = (error: any) => boolean
export type OpenRegisterProps = { openRegisterCompany: (args: { payload: {}; onError: OnregisterError }) => void }

const withRegisterCompanyModal = <T extends OpenRegisterProps>(Component: FC<T>) => (props) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { translate, currentLanguageNumber } = useTranslation()
  const [loadingRegister, setLoadingRegister] = useState(false)
  const { fromProduct } = useDimention()
  const router = useAppRouter()
  const [dataPayload, setDataPayload] = useState<any>()
  const onErrorRef = useRef<OnregisterError>()
  const fallBackUrl = router.query.redirect
  const storeTokenAndFetchUser = useStoreTokenAndFetchUser()
  const formData2Store = (values) => ({
    ...values,
    email: dataPayload?.email.toLowerCase() || '',
    full_name: dataPayload.full_name || '',
    photo_url: dataPayload.photo_url || '',
    provider: dataPayload.provider || 1
  })

  const onFinishRegister = (data) => {
    setLoadingRegister(true)
    const params = formData2Store(data)
    submitRegisterSocialAPI({ values: params, fromProduct, currentLanguageNumber })
      .then((response) => {
        return storeTokenAndFetchUser(response.data.token).then(() => {
          const jwt = readToken(response.data.token)

          router.push(fallBackUrl ? decodeURIComponent(toStringFromQuery(fallBackUrl)) : LINK_URL_COMPANY_JOB_LIST(jwt.company_id))
        })
      })
      .catch((error) => {
        if (!onErrorRef.current(error)) {
          throw error
        }
      })
      .finally(() => {
        setLoadingRegister(false)
        setOpen(false)
      })
  }
  const openRegisterCompany = useCallback<OpenRegisterProps['openRegisterCompany']>(({ payload, onError }) => {
    setOpen(true)
    setDataPayload(payload)
    onErrorRef.current = onError
    debugger
  }, [])
  return (
    <>
      {open && (
        <RegisterSocialModal
          open={open}
          onOk={() => {
            setOpen(false)
            setLoading(false)
          }}
          onFinish={onFinishRegister}
          loading={loadingRegister}
          dataPayload={dataPayload}
        />
      )}
      <Component {...props} openRegisterCompany={openRegisterCompany} />
    </>
  )
}
export default withRegisterCompanyModal
