import React, { FC } from 'react'
import { FirstSectionStyle } from 'src/@legacy/style/styled/LP/FirstSectionStyle.style'
import { WrapperLayout } from 'src/@legacy/@core/components/share/Wrapper/WrapperLayout'
import { Row, Col } from 'src/@legacy/@core/components/share/ANTD/Grid'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { Trans } from 'react-i18next'
import { Button } from 'src/@legacy/@core/components/share/ANTD'
import Image from 'next/image'

const FirstSection: FC<{ scrollToLastCall; isActive }> = ({ scrollToLastCall, isActive }) => {
  const { translate } = useTranslation()
  return (
    <>
      <style jsx>{FirstSectionStyle}</style>
      <div className="first-section">
        <WrapperLayout className="wrapper-container">
          <Row>
            <Col xs={24} lg={11}>
              <div className="block-left">
                <h1 className="heading">{translate('page_employer_lp:first_section_heading')}</h1>
                <div className="sub-title">
                  <Trans i18nKey="page_employer_lp:first_section_sub_title" values={{ number: process.env.NEXT_PUBLIC_NUMBER_CANDIDATES }}>
                    <span className="high-light" />
                  </Trans>
                </div>
                <div className="button-wrapper">
                  <Button size="large" type="primary" id="btn-post-job" className="btn btn-post-job" onClick={scrollToLastCall}>
                    {translate('page_employer_lp:first_section_button_post_job')}
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={24} lg={13}>
              <div className="block-right">
                <Row justify="end">
                  <Col xs={24} lg={20}>
                    <Row justify="center" gutter={[{ lg: 24 }, { xs: 16, md: 16, lg: 24 }]}>
                      <Col xs={24} lg={12}>
                        <div className="impress-card">
                          <div className="wrapper-image">
                            <Image src="/img/LP/first_icon_1.png" alt="icon_1" width={48} height={48} />
                          </div>
                          <div>
                            <div className="number">{process.env.NEXT_PUBLIC_NUMBER_CANDIDATES}+</div>
                            <div className="detail">{translate('page_employer_lp:first_section_number_candidates')}</div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[24, { xs: 16, md: 16, lg: 0 }]} justify="center">
                      <Col xs={24} lg={12}>
                        <div className="impress-card">
                          <div className="wrapper-image">
                            <Image src="/img/LP/first_icon_2.png" alt="icon_2" width={48} height={48} />
                          </div>
                          <div>
                            <div className="number">{process.env.NEXT_PUBLIC_NUMBER_COMPANIES}+</div>
                            <div className="detail">{translate('page_employer_lp:first_section_number_companies')}</div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} lg={12}>
                        <div className="impress-card">
                          <div className="wrapper-image">
                            <Image src="/img/LP/first_icon_3.png" alt="icon_3" width={48} height={48} />
                          </div>
                          <div>
                            <div className="number">{process.env.NEXT_PUBLIC_NUMBER_NEW_CANDIDATES}+</div>
                            <div className="detail">{translate('page_employer_lp:first_section_number_new_candidates')}</div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div className={`scroll-mouse ${!isActive && 'scroll-hide'}`}>
            <div className="scroll-text">
              {translate('page_employer_lp:scroll_mouse')}
              <div className="scroll-divider" />
              <div className="icon-mouse">
                <Image src="/img/LP/mouse.png" alt="mouse" width={24} height={24} />
              </div>
            </div>
          </div>
        </WrapperLayout>
      </div>
    </>
  )
}

export default FirstSection
