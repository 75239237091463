import css from 'styled-jsx/css'

export const FAQSectionStyle = css.global`
  .ant-collapse-header {
    margin: 0;
  }
  .qa-section {
    padding: 80px 0;
    background-color: #fff;
    position: relative;
  }
  .qa-section .faq-background {
    position: absolute;
    top: 0;
    left: 0;
  }
  .qa-section .heading {
    text-align: center;
    font-size: 36px;
    line-height: 46px;
    color: #262626;
    font-weight: 500;
    margin-bottom: 56px;
  }
  .qa-section > div {
    max-width: 792px;
    margin: 0 auto;
  }
  .qa-section .qa-section-collapse .ant-collapse > .ant-collapse-item {
    margin-bottom: 16px;
    border-radius: 8px;
    border: solid 1px #d9d9d9;
    background-color: #fff;
  }
  .qa-section .qa-section-collapse .ant-collapse > .ant-collapse-item:last-child {
    margin-bottom: 0;
  }
  .qa-section .qa-section-collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 18px 50px 18px 24px;
    font-size: 16px;
    color: #262626;
    font-weight: 500;
    line-height: 1.75;
    display: flex;
    align-items: flex-start;
  }
  .qa-section .qa-section-collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    width: 60px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  .qa-section .qa-section-collapse .ant-collapse-content > .ant-collapse-content-box {
    border-top: 1px solid #d9d9d9;
    margin: 0 24px;
    padding: 18px 0;
    font-size: 16px;
    color: #595959;
    line-height: 1.75;
  }
  .qa-section .qa-section-collapse .ant-collapse-content > .ant-collapse-content-box ul {
    margin: 0;
    padding-left: 25px;
  }
  @media screen and (max-width: 768px) {
    .qa-section {
      padding: 0 0 40px 0;
    }
    .qa-section .faq-background {
      display: none;
    }
    .qa-section .heading {
      font-size: 28px;
      margin-bottom: 32px;
    }
    .qa-section .qa-section-collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      font-size: 14px;
    }
    .qa-section .qa-section-collapse .ant-collapse-content > .ant-collapse-content-box {
      font-size: 14px;
    }
  }
`
