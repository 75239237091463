import React, { FC, useState, useEffect, useRef } from 'react'
import FirstSection from './components/FirstSection'
import WhySection from './components/WhySection'
import FeatureSection from './components/FeatureSection'
import PartnerSection from './components/PartnerSection'
import FeedbackSection from './components/FeedbackSection'
import NewsSection from './components/NewsSection'
import FAQSection from './components/FAQSection'
import FormSection from './components/FormSection'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { THUMBNAIL_COMPANY_LP } from 'src/@legacy/utilities/Constant'
import { LINK_URL_EMPLOYER_PRODUCT } from 'src/@legacy/utilities/LinkURL'
import env from 'src/@legacy/utilities/env'
import PublicPageMeta from 'src/@legacy/@core/components/SEOMeta/PublicPageMeta'
import LogoTags from './schema/LogoTags'
import FAQTags from './schema/FAQTags'
import LocalBussinessTags from './schema/LocalBusinessTags'
import { EliminateQueryCanonical } from 'src/@legacy/@optimal-page/wrapper/jobs/SEOMeta/CanonicalMeta'

const LpPageWrapper: FC = () => {
  const { translate } = useTranslation()
  const scrollRef = useRef()
  const scrollControlButton = ({ scrollRef }) => {
    const [isActive, setIsActive] = useState(false)
    const changePositionHeader = () => {
      if (scrollRef.current) {
        const elemRect = scrollRef.current.getBoundingClientRect()

        if (elemRect.top > 0) {
          setIsActive(true)
        } else {
          setIsActive(false)
        }
      }
    }
    useEffect(() => {
      window.addEventListener('scroll', changePositionHeader)
      return () => {
        window.removeEventListener('scroll', changePositionHeader)
      }
    }, [])
    return { isActive }
  }
  const { isActive } = scrollControlButton({ scrollRef })
  const scrollToLastCall = () => {
    window.scrollTo({
      top: document.getElementById('last-call').offsetTop,
      left: 0,
      behavior: 'smooth'
    })
  }

  return (
    <div>
      <style jsx global>
        {`
          .wrapper-container {
            max-width: 1200px;
          }
        `}
      </style>
      <EliminateQueryCanonical rootDomain={env.PUBLIC_EMPLOYER_APP_DOMAIN} />
      <LogoTags />
      <FAQTags />
      <LocalBussinessTags />
      <PublicPageMeta
        title={translate('meta_employer_product:title')}
        description={translate('meta_employer_product:description')}
        keywords={translate('meta_employer_product:keywords')}
        url={`${process.env.NEXT_PUBLIC_APP_DOMAIN}${LINK_URL_EMPLOYER_PRODUCT}`}
        image={`${process.env.NEXT_PUBLIC_APP_DOMAIN}${THUMBNAIL_COMPANY_LP}`}
      />

      <FirstSection scrollToLastCall={scrollToLastCall} isActive={isActive} />
      <WhySection />
      <FeatureSection />
      <PartnerSection />
      <FeedbackSection />
      <NewsSection />
      <FAQSection />
      <FormSection scrollRef={scrollRef} />
    </div>
  )
}

export default LpPageWrapper
