import Head from 'next/head'
import { FC } from 'react'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'

const LocalBussinessTags: FC = () => {
  const { translate } = useTranslation()
  const businessTags = {
    '@context': 'https://schema.org',
    '@type': 'EmploymentAgency',
    '@id': 'https://freec.asia',
    name: translate('page_home_business_tag:home_business_tag_name'),
    alternateName: translate('page_home_business_tag:home_business_alternate_name'),
    logo: `${process.env.NEXT_PUBLIC_EMPLOYER_APP_DOMAIN}/img/freec-logo-blue.svg`,
    image: `${process.env.NEXT_PUBLIC_EMPLOYER_APP_DOMAIN}/img/freec-logo-blue.svg`,
    description: translate('page_home_business_tag:home_business_description'),
    hasMap: translate('page_home_business_tag:home_business_has_map'),
    url: translate('page_home_business_tag:home_business_url'),
    telephone: translate('page_home_business_tag:home_business_telephone'),
    priceRange: 0,
    address: {
      '@type': 'PostalAddress',
      streetAddress: translate('page_home_business_tag:home_business_address_street_address'),
      addressLocality: translate('page_home_business_tag:home_business_address_address_locality'),
      addressRegion: translate('page_home_business_tag:home_business_address_address_region'),
      postalCode: translate('page_home_business_tag:home_business_address_address_postal_code'),
      addressCountry: translate('page_home_business_tag:home_business_address_address_country')
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: parseFloat(translate('page_home_business_tag:home_business_geo_latitude')),
      longitude: parseFloat(translate('page_home_business_tag:home_business_geo_long'))
    },
    openingHoursSpecification: {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: translate('page_home_business_tag:home_business_opening_hours_specification_day_of_weeks')?.split(','),
      opens: translate('page_home_business_tag:home_business_opening_hours_specification_open'),
      closes: translate('page_home_business_tag:home_business_opening_hours_specification_close')
    },
    sameAs: translate('page_home_business_tag:home_business_same_as')?.split(',')
  }
  return (
    <Head>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: `${JSON.stringify(businessTags)}` }} />
    </Head>
  )
}

export default LocalBussinessTags
