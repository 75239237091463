import css from 'styled-jsx/css'

export const DefaultLayoutCompanyStyle = css.global`
  .company-layout .header {
    position: fixed;
    z-index: 999;
    width: 100%;
    background: #fff;
    height: var(--layout-header-height);
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .company-layout .header.none-shadow {
    box-shadow: none;
    border-bottom: 1px solid #d9d9d9;
  }

  .company-layout .header .left-group,
  .company-layout .header .right-group {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    height: 100%;
  }
  .company-layout .header .right-group .view-company-name {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-right: 24px;
    border-right: 1px solid #e8e8e8;
  }

  .company-layout .header .right-group .view-company-name .label-career-site {
    font-family: Roboto;
    font-size: 12px;
    line-height: 20px !important;
    text-align: right;
    color: #4f535b;
    text-overflow: ellipsis;
    width: 200px;
    /* Needed to make it work */
    overflow: hidden;
    white-space: nowrap;
  }

  .company-layout .header .right-group .view-company-name .label-company-name {
    font-family: Roboto;
    font-size: 14px;
    line-height: 20px !important;
    font-weight: bold;
    text-align: right;
    color: #000;

    text-overflow: ellipsis;
    width: 200px;
    /* Needed to make it work */
    overflow: hidden;
    white-space: nowrap;
  }

  .company-layout .header .right-group .view-company-name .label-company-name:hover {
    color: #1890ff;
  }

  .header-chat {
    position: relative;
  }

  .header-chat::before {
    position: absolute;
    content: '';
    width: 32px;
    height: 32px;
    border-radius: 4px;
    top: -4px;
    left: -4px;
  }

  .header-chat:hover::before {
    background-color: #f5f5f5;
  }

  .header-chat:active path {
    fill: #1890ff !important;
  }

  .company-layout .header .left-group .header-logo-img,
  .company-layout .header .right-group .header-logo-img {
    /* height: 34px; */
    display: flex;
  }
  .logo-freec {
    display: inline-flex;
  }
  .logo-freec.logo-vn > span {
    width: 150px !important;
  }

  .company-layout .header .left-group .header-link-group {
    display: flex;
  }

  .company-layout .header .left-group .header-link-group,
  .company-layout .header .right-group .header-link-group {
    padding-left: 40px;
  }

  .company-layout .header .left-group .header-link,
  .company-layout .header .right-group .header-link {
    font-weight: 500;
    font-size: 14px;
    color: #4f535b;
    margin-right: 40px;
    position: relative;
  }

  .company-layout .header .left-group .header-link.active {
    color: #1890ff;
  }

  .company-layout .header .left-group .header-link:before,
  .company-layout .header .right-group .header-link:before {
    content: '';
    width: 0;
    height: 2px;
    background-color: #1890ff;
    position: absolute;
    bottom: 1px;
    left: 0;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
  }

  .company-layout .header .left-group .header-link.active:before,
  .company-layout .header .left-group .header-link:hover:before,
  .company-layout .header .right-group .header-link.active:before,
  .company-layout .header .right-group .header-link:hover:before {
    width: 100%;
  }

  .company-layout .header .left-group .header-link__dropdown-icon {
    color: #6c7b8d;
    font-size: 12px;
    margin-left: 4px;
    top: calc(50% - 6px);
    position: absolute;
  }
  .company-layout .header .left-group .header-link:hover .header-link__dropdown-icon {
    /* transform: rotate(180deg); */
    color: #1890ff;
  }
  .company-layout .header .left-group .header-link__dropdown:hover {
    color: #1890ff;
  }
  .company-layout .header .left-group .ant-dropdown-menu {
    padding: 8px 0;
    border-radius: 4px;
  }
  .company-layout .header .left-group .ant-dropdown-menu-item > a {
    padding: 8px 12px;
    font-size: 16px;
    line-height: 24px;
    color: #141921;
    min-width: 140px;
  }

  .company-layout .header .right-group {
    margin-left: auto;
  }

  .company-layout .header .right-group .btn-create-job {
    margin-right: 40px;
  }

  .company-layout .header .right-group .dropdown-profile {
    margin-left: 24px;
  }

  .company-layout .header .profile-drop-down-link {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: right;
    color: #141921;
    font-weight: 600;
  }
  .company-layout .header .profile-drop-down-link.language-dropdown {
    padding: 22px 0 22px 8px;
    border-radius: 4px;
  }

  /* .company-layout .header .profile-drop-down-link:hover {
    background-color: #f1f2f3;
  } */

  .company-layout .header .profile-drop-down-link img.lang-flag {
    height: 13px;
    margin-right: 4px;
    border-radius: 2px;
  }

  .company-layout .header .profile-header-name {
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #141921;
    font-weight: 600;
    font-size: 14px;
  }

  .company-layout .header .image-flag {
    height: 16px;
  }

  .company-layout .right-group .divider-vertical {
    height: 2.9em;
    margin: 0 24px;
    border-left: 1px solid #e8e8e8;
  }
  .company-layout .right-group .btn-candidate {
    padding: 0;
    border: none;
    text-align: left;
  }
  .company-layout .right-group .btn-candidate .title {
    font-size: 14px;
    color: #1890ff;
    font-weight: 500;
  }
  .company-layout .right-group .btn-candidate .sub-title {
    font-size: 12px;
    color: #8c8c8c;
  }

  .company-layout .right-group .btn-login {
    color: #141921;
    font-weight: 500;
    padding: 7px 16px;
    border: 1px solid #d1d7dc;
    background-color: #fff;
    font-size: 14px;
    border-radius: 4px;
    margin-right: 8px;
  }
  .company-layout .right-group .btn-register {
    color: #fff !important;
    font-weight: 500;
    padding: 7px 16px;
    background-color: #1890ff;
    font-size: 14px;
    border-radius: 4px;
  }
  .company-layout .right-group .btn-register:hover {
    color: #fff;
  }

  @media only screen and (max-width: 768px) {
    .company-layout .header {
      display: none;
    }
  }

  .company-layout .container {
    margin-top: var(--layout-header-height);
    min-height: -moz-calc(100vh - var(--layout-footer-height) - var(--layout-header-height));
    min-height: calc(100vh - var(--layout-footer-height) - var(--layout-header-height));
    background-color: #f6f8fc;
  }

  .company-layout .footer {
    height: var(--layout-footer-height);
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    background-color: #f6f8fc;
  }

  .company-layout .footer .footer-item {
    margin: 0 24px;
  }

  .company-layout .footer .footer-link {
    font-weight: 600;
    font-size: 12px;
    color: #000;
    margin: 0 24px;
  }

  .company-layout .header .right-group .header-sign-in-up {
    margin-right: 19px;
  }
  .company-layout .header .right-group .header-sign-in-up a {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    text-decoration: none;
    color: #141921;
  }
  .company-layout .header .right-group .header-sign-in-up a:hover {
    color: #1890ff;
  }
  .company-layout .header .right-group .header-sign-in-up span {
    margin: 0 5px;
  }
  .company-layout .header .right-group .header-employer {
    margin-left: 16px;
    padding-left: 15px;
    border-left: 1px solid #e8e8e8;
  }
  .company-layout .header .right-group .header-employer .btn {
    padding: 0;
    border: 0 none;
    background-color: transparent;
    cursor: pointer;
  }
  .company-layout .header .right-group .header-employer .btn .icon-left {
    border-radius: 50%;
    margin: 0 12px 0 0;
    background-color: #e6f7ff;
  }
  .company-layout .header .right-group .header-employer .btn-info .title {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    color: #1890ff;
    line-height: 20px;
  }
  .company-layout .header .right-group .header-employer .btn-info .sub-title {
    font-size: 12px;
    color: #141921;
    line-height: 20px;
  }
  .company-layout .header .right-group .header-employer .btn .icon-right {
    margin-left: 16px;
    line-height: 1;
  }

  @media screen and (max-width: 768px) {
    .company-layout .header.header-hide-mobile {
      display: none;
    }
  }

  @media only screen and (max-width: 576px) {
    .company-layout .footer {
      -webkit-flex-direction: column-reverse;
      -moz-box-orient: vertical;
      -moz-box-direction: reverse;
      flex-direction: column-reverse;
    }

    .company-layout .footer .footer-item {
      margin: 15px 0;
    }
  }
`
