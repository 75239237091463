import React, { FC } from 'react'
import { WrapperLayout } from 'src/@legacy/@core/components/share/Wrapper/WrapperLayout'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { FeedbackSectionStyle } from 'src/@legacy/style/styled/LP/FeedbackSectionStyle.style'
import { Row, Col } from 'src/@legacy/@core/components/share/ANTD/Grid'
import SlickSlider from 'src/@legacy/components/SlickSlider'
import { LIST_FEEDBACK } from 'src/@legacy/utilities/Enum'
import Image from 'next/image'

const FeedbackSection: FC = () => {
  const { translate } = useTranslation()
  const settings = {
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    prevArrow: (
      <button className="slick-prev">
        <img src="/img/LP/arrow_left.svg" alt="arrow-left" className="img-fluid" />
      </button>
    ),
    nextArrow: (
      <button className="slick-next">
        <img src="/img/LP/arrow_right.svg" alt="arrow-right" className="img-fluid" />
      </button>
    ),
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <>
      <style jsx>{FeedbackSectionStyle}</style>
      <div className="feedback-section">
        <WrapperLayout className="wrapper-container">
          <Row gutter={[{ xs: 0, md: 0, lg: 96 }, 0]}>
            <Col xs={24} md={24} lg={6}>
              <h3 className="heading">{translate('page_employer_lp:feedback_section_heading')}</h3>
            </Col>
            <Col xs={24} md={24} lg={18}>
              <div className="block-right">
                <SlickSlider {...settings}>
                  {LIST_FEEDBACK.map((item) => (
                    <div className="card-wrapper" key={item.id}>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="name">{item.name}</div>
                          <div className="position">{item.position}</div>
                        </div>
                        <div className="image-wrapper">
                          <Image src="/img/LP/command.png" alt="command" width={48} height={41} />
                        </div>
                      </div>
                      <div className="content">{item.content}</div>
                    </div>
                  ))}
                </SlickSlider>
                <div className="image-background">
                  <Image src="/img/LP/feedback_bg.png" alt="bg" width={217} height={199} />
                </div>
              </div>
            </Col>
          </Row>
        </WrapperLayout>
      </div>
    </>
  )
}

export default FeedbackSection
