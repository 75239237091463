import { Layout } from 'src/@legacy/@core/components/share/ANTD/Layout'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import React from 'react'
import { DefaultLayoutCompanyStyle } from '../../style/styled/DefaultLayoutCompany.style'
import { FreeCNextPage } from '../../type/Common'

const HeaderBeforeLogin = dynamic(() => import('../HeaderBeforeLogin'))
const Footer = dynamic(() => import('../FooterAfterLogin'))

const withHeaderLP = (Page: FreeCNextPage, className?: string) => {
  const HOCCom = (props: any) => {
    return (
      <>
        <Head>
          <script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&libraries=places`}></script>
        </Head>
        <Layout className={className || 'company-layout'}>
          {!className && (
            <style jsx global>
              {DefaultLayoutCompanyStyle}
            </style>
          )}

          <HeaderBeforeLogin />
          <div className="container">
            <Page {...props} />
          </div>
          <Footer />
        </Layout>
      </>
    )
  }
  HOCCom.getInitialProps = Page.getInitialProps
  return HOCCom
}
export default withHeaderLP
