import Head from 'next/head'
import { FC } from 'react'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'

const FAQTags: FC = () => {
  const { translate } = useTranslation()
  const faqObject = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: translate('page_employer_lp:qa_question_one'),
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>${translate('page_employer_lp:qa_answer_one')}</p>`
        }
      },
      {
        '@type': 'Question',
        name: translate('page_employer_lp:qa_question_two'),
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>${translate('page_employer_lp:qa_answer_two')}</p>`
        }
      },
      {
        '@type': 'Question',
        name: translate('page_employer_lp:qa_question_three'),
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>${translate('page_employer_lp:qa_answer_three')}</p>`
        }
      },
      {
        '@type': 'Question',
        name: translate('page_employer_lp:qa_question_four'),
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>${translate('page_employer_lp:qa_answer_four')}</p>`
        }
      },
      {
        '@type': 'Question',
        name: translate('page_employer_lp:qa_question_five'),
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>${translate('page_employer_lp:qa_answer_five')}</p>`
        }
      },
      {
        '@type': 'Question',
        name: translate('page_employer_lp:qa_question_six'),
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>${translate('page_employer_lp:qa_answer_six')}</p>`
        }
      },
      {
        '@type': 'Question',
        name: translate('page_employer_lp:qa_question_seven'),
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>${translate('page_employer_lp:qa_answer_seven')}</p>`
        }
      }
    ]
  }
  return (
    <Head>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: `${JSON.stringify(faqObject)}` }} />
    </Head>
  )
}

export default FAQTags
