import React, { FC } from 'react'
import { FeatureSectionStyle } from 'src/@legacy/style/styled/LP/FeatureSectionStyle.style'
import { WrapperLayout } from 'src/@legacy/@core/components/share/Wrapper/WrapperLayout'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { Row, Col } from 'src/@legacy/@core/components/share/ANTD'
import Image from 'next/image'

const FeatureSection: FC = () => {
  const { translate } = useTranslation()
  return (
    <>
      <style jsx>{FeatureSectionStyle}</style>
      <div className="feature-section">
        <WrapperLayout className="wrapper-container">
          <h2 className="heading">{translate('page_employer_lp:feature_section_heading')}</h2>
          <Row>
            <Col xs={24} md={24} lg={22}>
              <Row
                gutter={[
                  { xs: 0, md: 0, lg: 90 },
                  { xs: 32, md: 32, lg: 120 }
                ]}
                align="middle">
                <Col xs={24} md={24} lg={14}>
                  <div className="image-wrapper">
                    <Image quality={100} src="/img/LP/feature_01.png" alt="feature_1" width={550} height={220} />
                  </div>
                </Col>
                <Col xs={24} md={24} lg={10}>
                  <div className="content">
                    <h3 className="title">{translate('page_employer_lp:feature_section_title_1')}</h3>
                    <div className="sub-title">{translate('page_employer_lp:feature_section_detail_1')}</div>
                  </div>
                </Col>
              </Row>
              <Row
                gutter={[
                  { xs: 0, md: 0, lg: 90 },
                  { xs: 32, md: 32, lg: 120 }
                ]}
                align="middle">
                <Col xs={24} md={24} lg={10} className="order-under">
                  <div className="content">
                    <h3 className="title">{translate('page_employer_lp:feature_section_title_2')}</h3>
                    <div className="sub-title">{translate('page_employer_lp:feature_section_detail_2')}</div>
                  </div>
                </Col>
                <Col xs={24} md={24} lg={14}>
                  <div className="image-wrapper">
                    <Image quality={100} src="/img/LP/feature_02.png" alt="feature_2" width={550} height={220} />
                  </div>
                </Col>
              </Row>
              <Row
                gutter={[
                  { xs: 0, md: 0, lg: 90 },
                  { xs: 32, md: 32, lg: 120 }
                ]}
                align="middle">
                <Col xs={24} md={24} lg={14}>
                  <div className="image-wrapper">
                    <Image quality={100} src="/img/LP/feature_03.png" alt="feature_3" width={550} height={220} />
                  </div>
                </Col>
                <Col xs={24} md={24} lg={10}>
                  <div className="content">
                    <h3 className="title">{translate('page_employer_lp:feature_section_title_3')}</h3>
                    <div className="sub-title">{translate('page_employer_lp:feature_section_detail_3')}</div>
                  </div>
                </Col>
              </Row>
              <Row
                gutter={[
                  { xs: 0, md: 0, lg: 90 },
                  { xs: 32, md: 32, lg: 120 }
                ]}
                align="middle">
                <Col xs={24} md={24} lg={10} className="order-under">
                  <div className="content">
                    <h3 className="title">{translate('page_employer_lp:feature_section_title_4')}</h3>
                    <div className="sub-title">{translate('page_employer_lp:feature_section_detail_4')}</div>
                  </div>
                </Col>
                <Col xs={24} md={24} lg={14}>
                  <div className="image-wrapper">
                    <Image quality={100} src="/img/LP/feature_04.png" alt="feature_4" width={550} height={220} />
                  </div>
                </Col>
              </Row>
              <Row
                gutter={[
                  { xs: 0, md: 0, lg: 90 },
                  { xs: 32, md: 32, lg: 120 }
                ]}
                align="middle">
                <Col xs={24} md={24} lg={14}>
                  <div className="image-wrapper">
                    <Image quality={100} src="/img/LP/feature_05.png" alt="feature_5" width={550} height={220} />
                  </div>
                </Col>
                <Col xs={24} md={24} lg={10}>
                  <div className="content">
                    <h3 className="title">
                      {translate('page_employer_lp:feature_section_title_5')}
                      <div className="tag-beta">Beta</div>
                    </h3>
                    <div className="sub-title">{translate('page_employer_lp:feature_section_detail_5')}</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </WrapperLayout>
        <div className="image-background">
          <Image src="/img/LP/feature_bg.png" alt="bg" width={400} height={700} />
        </div>
      </div>
    </>
  )
}

export default FeatureSection
