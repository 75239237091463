import css from 'styled-jsx/css'

export const FeedbackSectionStyle = css.global`
  .feedback-section {
    padding: 90px 0 160px 0;
    background-color: #fff;
  }
  .feedback-section .heading {
    font-size: 36px;
    line-height: 46px;
    color: #262626;
    white-space: break-spaces;
  }
  body .slick-list {
    padding: 10px 0;
  }
  body .slick-track {
    display: flex;
  }
  body .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center; //optional
    justify-content: center; //optional
  }
  body .slick-track .slick-slide > div {
    height: 100%;
  }

  .feedback-section .block-right {
    position: relative;
  }
  .feedback-section .image-background {
    position: absolute;
    left: -50px;
    bottom: -130px;
    z-index: 1;
  }
  .feedback-section .card-wrapper {
    padding: 32px;
    background: #ffffff;
    box-shadow: 1px 3px 16px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    height: 100%;
  }
  .feedback-section .card-wrapper .name {
    font-size: 20px;
    line-height: 28px;
    color: #262626;
    font-weight: 500;
  }
  .feedback-section .card-wrapper .postion {
    font-size: 16px;
    line-height: 24px;
    color: #262626;
  }
  .feedback-section .card-wrapper .content {
    font-size: 16px;
    line-height: 24px;
    color: #595959;
    margin-top: 16px;
  }

  .feedback-section .slick-slider {
    width: 100%;
    z-index: 3;
  }

  .feedback-section .slick-slide {
    padding: 10px 14px;
  }
  .feedback-section .slick-next {
    right: 50px;
    top: 100%;
  }
  .feedback-section .slick-prev {
    top: 100%;
    right: 116px;
    left: unset;
  }
  @media screen and (max-width: 768px) {
    .feedback-section {
      padding: 40px 0 100px 0;
    }
    .feedback-section .heading {
      text-align: center;
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 32px;
    }
    .feedback-section .image-background {
      display: none;
    }
    .feedback-section .card-wrapper {
      padding: 16px;
    }
    .feedback-section .card-wrapper .content {
      font-size: 14px;
      margin-top: 8px;
    }
  }
  @media screen and (max-width: 576px) {
    .feedback-section .slick-next {
      right: 45%;
    }
    .feedback-section .slick-prev {
      right: 65%;
    }
  }
`
